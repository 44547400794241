html,
body{
  box-sizing: border-box;
  overflow-x: hidden;
}

.anim{
  width: 100vw;
  height: 100vh;
  background-color: #ff8896;
}

#overlay {
	position: absolute;
	font-size: 16px;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,0.7);
}

	#overlay button {
		background: transparent;
		border: 0;
		border: 1px solid rgb(255, 255, 255);
		border-radius: 4px;
		color: #ffffff;
		padding: 12px 18px;
		text-transform: uppercase;
		cursor: pointer;
	}
.words{
	padding: 5%;
	color: white;
	text-transform: uppercase;
}